@value breakpointLap from "../Breakpoints/Breakpoints.css";
@value fontNormal from "../Typography/Typography.css";
@value shadow1 from "../Shadows/Shadows.css";
@value colorBlackA40 from "../Colors/Colors.css";
@value colorWhite from "../Colors/Colors.css";

.form {
	width: 100%;
	margin-top: 2.4rem;

	max-width: 960%;
}

@media (min-width: breakpointLap) {
	.form {
		margin-top: 4rem;
	}
}

.input {
	/* Browser normalization */
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-webkit-appearance: none;
	border: none;
	outline: none;
	line-height: normal; /* Fix for safari */

	composes: fontNormal;

	padding: 1.5rem 2.8rem; /* 64px */
	font-size: 2rem;

	border-radius: 40px;
	box-shadow: shadow1;
	background: colorWhite;
	display: inline-block;
	cursor: text;
	width: 100%;

	color: inherit;
	/* font-family: inherit; */
}

@media (min-width: breakpointLap) {
	.input {
		padding: 1.9rem 3.2rem 2rem; /* 80px */
		font-size: 2.4rem;
	}
}

.input::placeholder {
	color: colorBlackA40;
}
