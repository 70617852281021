@value colorPurpleA80 from "../Colors/Colors.css";
@value colorWhite from "../Colors/Colors.css";
@value colorWhiteA30 from "../Colors/Colors.css";
@value fontTitle from "../Typography/Typography.css";
@value fontNormal from "../Typography/Typography.css";

.dialogMask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: colorPurpleA80;
	display: flex;
	justify-content: center;
	align-items: center;
	/* pointer-events: none; */
}

.dialog {
	background: colorWhite;
	width: 90%;
	max-width: 440px;
	padding: 32px;
	border-radius: 32px;
}

.dialogClose {
	border: none; /* Removes default <button> border */
	outline: none; /* Removes Chrome outline */
	text-decoration: none; /* Link buttons */
	background: none;

	box-shadow: inset 0 0 0 2px colorWhiteA30;
	padding: 0.8rem;
	display: flex;
	border-radius: 50%;
	position: fixed;
	top: 16px;
	right: 16px;
	transition: box-shadow ease-in-out 0.15s;
	cursor: pointer;
}

.dialogClose:hover {
	box-shadow: inset 0 0 0 2px colorWhite;
}

.heading {
	composes: fontTitle;
}

.paragraph {
	composes: fontNormal;
}
