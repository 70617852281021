.waves {
	overflow: hidden;
	position: absolute;
	width: 100%;
	bottom: -48px;
	height: 48px;
	z-index: -1; /* Put behind content */
}

.wave {
	background: url("./wave-pink.svg") repeat-x;
	position: absolute;
	top: 0;
	width: 6400px; /* 4x width of image so there's no gap */
	height: 48px;
	animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
	transform: translate3d(0, 0, 0);
	mix-blend-mode: multiply;
}

.wave:nth-of-type(2) {
	background: url("./wave-blue.svg") repeat-x;
	animation: wave 16s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
		swell 16s ease -1.25s infinite;
}

/* Animations */

@keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -1600px;
	}
}

@keyframes swell {
	0%,
	100% {
		transform: translate3d(0, -8px, 0);
	}
	50% {
		transform: translate3d(0, 0, 0);
	}
}
