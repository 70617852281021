@value colorBlackA90 from "../Colors/Colors.css";
@value colorBlackA40 from "../Colors/Colors.css";
@value colorBlackA15 from "../Colors/Colors.css";
@value colorWhite from "../Colors/Colors.css";
@value fontNormal from "../Typography/Typography.css";
@value colorPurpleA05 from "../Colors/Colors.css";
@value shadow1 from "../Shadows/Shadows.css";

.translations {
	width: 94%;
	max-width: 800px;
	margin: 0 auto;
	padding: 0;

	flex: 1; /* For sticky footer */
}

.translation {
	display: flex;
	composes: fontNormal;
	background: colorWhite;
	box-shadow: shadow1;
	margin-top: 16px;
	border-radius: 32px;
	opacity: 1;

	transition: all 0.2s ease-in-out;
}

.translation:last-child {
	margin-bottom: 32px;
}

.translation.isHidden {
	opacity: 0;
	transform: translateY(16px);
}

.translationLanguage {
	width: 32px;
	height: 32px;
	background: colorPurpleA05;
	margin: 16px;
	border-radius: 50%;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
	text-align: center;
}

.translationLanguage img {
	border-radius: inherit;
}

.translationLanguage::before {
	content: "";
	box-shadow: inset 0 0 0 1px colorBlackA15;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: inherit;
}

.translationLanguage[alt]::after {
	content: attr(alt);
	position: absolute;
	top: -28px;
	left: 0;
	background: colorBlackA90;
	color: colorWhite;
	border-radius: 4px;
	font-size: 1.6rem;
	padding: 2px 6px 1px;
	white-space: nowrap;
	opacity: 0;
	transition: all 0.2s ease-in-out;
	pointer-events: none;

	transform: translateY(2px);
}

.translationLanguage[alt]:hover::after {
	opacity: 1;
	transform: translateY(0);
}

.translationText {
	vertical-align: middle;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

	flex-grow: 1;
	margin: 18px 0;
}

.translationLength {
	margin: 1.8rem 2.8rem 1.8rem 1.6rem;
	font-size: 1.4rem;
	line-height: 1;
	color: colorBlackA40;
	padding: 0;
	list-style: none;
	text-align: right;

	white-space: nowrap;
}
