@value colorBlackA40 from "../Colors/Colors.css";
@value colorBlackA15 from "../Colors/Colors.css";
@value fontNormal from "../Typography/Typography.css";

.footer {
	composes: fontNormal;
	color: colorBlackA40;
	text-align: center;
	margin: 0 0 40px;
}

.footer button {
	border: none; /* Removes default <button> border */
	outline: none; /* Removes Chrome outline */
	text-decoration: none; /* Link buttons */
	text-decoration: underline;
	background: none;
	padding: 0;
	margin: 0;
}

.footer a,
.footer button {
	color: inherit;
	text-decoration-color: colorBlackA15;
	cursor: pointer;
}

.footer a:hover,
.footer button:hover {
	text-decoration-color: colorBlackA40;
}
