@value colorPurpleA05 from "../Colors/Colors.css";

* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

img {
	max-width: 100%;
	vertical-align: top;
}

.App {
	background: colorPurpleA05;

	/* Fixed footer */
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
