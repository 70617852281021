@value breakpointLap from "../Breakpoints/Breakpoints.css";
@value colorBlack from "../Colors/Colors.css";
@value colorBlackA15 from "../Colors/Colors.css";

/* Font stacks */

@value defaultFontStack: "Jaldi", "Helvetica Neue", sans-serif;

/* Font scale */

@value titleFontSize: 2.8rem;
@value normalFontSize: 2.0rem;

@value titleFontSizeDesktop: 3.6rem;

/* Line height */

@value titleLineHeight: 1.1;
@value normalLineHeight: 1.4;

/* Base font styles */

.fontBase {
	font-family: defaultFontStack;
	line-height: normalLineHeight;
	color: colorBlack;
	margin-top: 0;
	margin-bottom: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	font-feature-settings: "liga";
}

/* UI typography */

.fontTitle {
	composes: fontBase;
	font-size: titleFontSize;
	line-height: titleLineHeight;

	margin-top: 2.4rem;
	margin-bottom: 0.8rem;
}

.fontNormal {
	composes: fontBase;
	font-size: normalFontSize;

	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
}

.links {
	color: inherit;
	text-decoration-color: colorBlackA15;
	transition: all ease-in-out 0.1s;

	&:hover {
		text-decoration-color: $blackA60;
	}
}

@media screen and (min-width: breakpointLap) {
	.fontTitle {
		font-size: titleFontSizeDesktop;
	}
}

.fontTitle:first-child,
.fontNormal:first-child {
	margin-top: 0;
}

.fontTitle:first-child,
.fontNormal:first-child {
	margin-bottom: 0;
}
