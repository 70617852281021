@value colorBlackA40 from "../Colors/Colors.css";
@value fontNormal from "../Typography/Typography.css";

.loader {
	text-align: center;
	margin-top: 8rem;
}

/* @media (min-width: $lap) {
  margin-top: 12.8rem;
} */

.loaderImage {
	position: relative;
	width: 58px;
	height: 32px;
	margin: 0 auto;
	animation: scaleBubble 1.5s ease-in-out infinite;
}

.loaderImage img {
	position: absolute;
	left: 0;
}

.loaderImage img:nth-of-type(2),
.loaderImage img:nth-of-type(3),
.loaderImage img:nth-of-type(4) {
	animation: fadeDots 0.6s ease-in-out infinite;
}

.loaderImage img:nth-of-type(3) {
	animation-delay: 0.1s;
}

.loaderImage img:nth-of-type(4) {
	animation-delay: 0.2s;
}

.loaderText {
	composes: fontNormal;
	margin: 1.6rem 4.8rem 0;
	color: colorBlackA40;
}

/* Animations */

@keyframes scaleBubble {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(calc(64 / 58));
	}
}

@keyframes fadeDots {
	0%,
	60% {
		opacity: 1;
	}
	30% {
		opacity: 0.5;
	}
}
