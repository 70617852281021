@value breakpointLap from "../Breakpoints/Breakpoints.css";
@value breakpointDesk from "../Breakpoints/Breakpoints.css";
@value breakpointWall from "../Breakpoints/Breakpoints.css";
@value fontTitle from "../Typography/Typography.css";
@value colorPurple from "../Colors/Colors.css";
@value colorWhiteA30 from "../Colors/Colors.css";

header {
	background: colorPurple;
	display: flex;
	position: relative; /* For waves */
}

.container {
	width: 94%;
	max-width: 960px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 3.2rem auto 2.4rem;
}

:global(.homepage) .container {
	margin-top: 8rem;
}

@media (min-width: breakpointLap) {
	.container {
		margin: 4.8rem auto 2.4rem;
	}
}

@media (min-width: breakpointDesk) {
	.container {
		margin: 9.6rem auto 3.2rem;
	}
}

@media (min-width: breakpointWall) {
	.container {
		margin: 12rem auto 4.8rem;
	}
}

@media (min-width: breakpointDesk) and (min-height: breakpointDesk) {
	.container {
		margin-top: 22.4rem;
	}
}

.logo {
	max-width: 70%;
}

.tagline {
	composes: fontTitle;
	color: colorWhiteA30;
	margin-top: 1.6rem;
}
